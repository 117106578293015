<template>
  <v-dialog
      v-model="showInscribete"
      persistent
      width="unset"
  >
    <v-card>
      <v-card-title>Inscribete</v-card-title>
      <v-card-text>
    <v-row>
      <v-col md="12" lg="12" cols="12">
        <v-autocomplete
            v-model="contactoid"
            :items="contactos"
            item-text="contacto"
            item-value="id"
            label="Elegir tu Promotor del Evento"
            @change="escogeContacto"
            persistent-hint
            single-line
        ></v-autocomplete>
      </v-col>
      <v-col md="12" lg="12" cols="12">
       <v-btn class="error" @click="cerrar">Cerrar</v-btn> <v-btn class="primary" @click="Inscribirse" :disabled="disabled">Inscribete</v-btn>
      </v-col>
    </v-row></v-card-text></v-card>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import store from "@/store";

export default {
    name: 'inscripcion',
  props:{
    eventoentrada: {type: Object, default: null}
  },
    data: () => ({
      overlay:false,
      contactos:[],
      disabled:true,
      contactoid:0
    }),
  watch:{
    dialog: function(){
      this.dameContactos()
    }
  },
  methods:{
    dameContactos() {
      this.overlay = true
      axios
      .get('contactos/evento/'+this.evento.id)
          .then(response => {
            this.overlay = false;
            this.contactos = response.data
          })
          .catch(error => {
            this.overlay = false;
            console.error(error);
          });
    },
    escogeContacto(){
      this.disabled = false
    },
    cerrar(){
      store.dispatch("hideInscripcion")
    },
    Inscribirse(){
      this.overlay = true
      var bodyFormData = new FormData();
      bodyFormData.append('personaId',  this.usuario.id);
      bodyFormData.append('entradaeventoId',  this.eventoentrada.id);
      bodyFormData.append('contactoId',  this.contactoid);
      axios
      .post('tickets/cortesia/contacto',bodyFormData)
          .then(response => {
            this.overlay = false;
            store.dispatch("hideInscripcion")
            console.log(response)
          })
          .catch(error => {
            this.overlay = false;
            store.dispatch("hideInscripcion")
            console.error(error);
          });
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario",
      evento: "evento",
      showInscribete: "showInscribete"
    })
  }
}
</script>