
<template>
  <v-app>
    <cabecera :dialog="dialog" />
    <v-main  style="background: #eef5f9">
      <v-container>

        <v-row  justify="center">
          <v-col  cols="12" lg="6" md="6" sm="12">
            <v-card>
              <v-card-title><v-btn icon :top="top" :right="right" @click="$router.go(-1)">
                <v-icon>far fa-times-circle</v-icon>
              </v-btn></v-card-title>
              <v-card-text><v-row >
                <v-col cols="12" md="12" lg="12" sm="12">
                  <center><h1> {{evento.nombre}}</h1></center>
                </v-col>
                <v-divider inset></v-divider>

                <v-col cols="12" sm="12" lg="12" md="12">
                  <center><h2> {{organizador}}</h2></center>
                </v-col>

                <v-col cols="12" lg="4" sm="6" md="4">
                  <v-img v-if="foto=='sinimagen'" max-height="300" contain  src="@/assets/sinimagen.jpeg"
                  ></v-img>
                  <v-img v-else max-height="300" contain :src="foto"></v-img>

                </v-col>
                <v-col cols="12" lg="8" sm="12" md="8">
                  <v-row>
                    <v-col cols="12" sm="12" lg="12" md="12">

                      Hora de Inicio {{horaInicio}}
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
                <v-row  justify="center">
                  <v-col cols="12" sm="12" lg="5" md="5">

                    <h2>Descripción de Evento</h2>

                  </v-col>
                  <v-col cols="12" sm="12" lg="4" md="4">

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card></v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-card>
              <v-card-title>Entradas</v-card-title>
<v-card-text>
  <div v-if="conectado === true">
  <v-data-table
      :headers="headers"
      :items="entradas"
      class="elevation-1"

  >
    <template v-slot:[`item.action`]="{ item }">
      <div  v-if="item.tiene">
       Adquirida
      </div>
      <div v-else>
        <div v-if="compareDates(item)">

        </div>
        <div v-else>

        </div>
        <v-btn color="primary" @click="inscribirse(item)">Inscribirse</v-btn>
      </div>
    </template>
    <template v-slot:[`item.price`]="{ item }">
      {{item.eventoentrada.moneda.shortmoneda}} {{item.eventoentrada.precio}}
    </template>
  </v-data-table></div>
  <div v-else>
    <v-data-table
        :headers="cabeceras"
        :items="entradaseve"
        sort-desc
        class="elevation-1"
    >
      <template v-slot:[`item.action`]="{}">
        <v-btn color="error" @click="abreconect">Conectarse</v-btn>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{item.moneda.shortmoneda}} {{item.precio}}
      </template>
    </v-data-table></div>
</v-card-text>
            </v-card>
          </v-col>
        </v-row>


      </v-container>
</v-main>
    <inscripcion :eventoentrada="entradaevento"/>
<piepagina />
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
</v-app>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import cabecera from './general/cabecera'
import piepagina from './general/piepagina';
import moment from "moment-timezone";
import store from "@/store";
import Inscripcion from "@/components/modals/inscripcion";

export default {
  name: 'evento',
  components: {
    Inscripcion,
    cabecera,
    piepagina,

  },
  data () {
    return {
      clientesimg: [],
      tag: '',
      dialog:false,
      inscribete: false,
      entradas:[],
      entradaseve:[],
      entradaevento:{},
      top:true,
      right:true,
      direction:'top',
      center: { lat: 45.508, lng: -73.587 },
      foto:'',
      organizador:'',
      horaInicio:'',
      horaFinal:'',
      overlay:false,
      conectado:false,
      emailRules: [
        v => !!v || 'Se necesita el Email',
        v => /.+@.+/.test(v) || 'E-mail debe ser valido',
      ],
      headers: [
        { text: 'Acciones', value: 'action', sortable: false },
        { text: 'Entrada', align: 'left',  value: 'eventoentrada.eventoEntrada'},
        { text: 'Precio', value: 'price' }
      ],
      cabeceras: [
        { text: 'Acciones', value: 'action', sortable: false },
        { text: 'Entrada', align: 'left',  value: 'eventoEntrada'},
        { text: 'Precio', value: 'price' }
      ]
    }
  },
  mounted(){
    this.tag =  this.$route.params.tag
    this.traeActo()
    if(this.usuario.id>0){
      this.conectado = true

    }else{
      this.conectado = false
    }
  },
  watch:{
    '$store.state.usuario': function (){
      this.conectado = true
      this.dameEntradas()
    },
    '$store.state.showInscribete': function (){
      this.dameEntradas()
    }
  },
  methods:{
    abreconect(){
      this.dialog = true
    },
    formatDatee(value) {
      return moment(value).locale('es-pe').format("DD MMMM YYYY")
    },
    traeActo(){
      this.overlay = true
      axios.get("actos/"+this.tag, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
          .then((response)  => {
            store.dispatch("fetchEvento", response.data);
            this.overlay=false
            this.dameEntradas()
            if(this.evento.foto === '' || this.evento.foto === null){

              if (this.evento.negocio === null ){
                if(this.evento.cliente === null){
                  this.foto='sinimagen'
                }else{
                  this.foto = this.evento.cliente.logo
                }
              }else{
                this.foto = this.evento.negocio.logo
              }}else{
              this.foto = this.evento.foto
            }
            if (this.evento.negocio == null ){
              if(this.evento.cliente == null){
                this.organizador=''
              }else{
                this.organizador=this.evento.cliente.cliente
              }
              this.center = {
                lat: parseFloat(this.evento.lat),
                lng: parseFloat(this.evento.lng)
              }
              this.horaInicio = this.evento.horaInicio
              this.horaFinal = this.evento.horaFinal
            }else{
              this.organizador = this.evento.negocio.negocioNombre
              this.horaInicio = this.evento.negocio.horaApertura
              this.horaFinal = this.evento.negocio.horaCierre

              this.center = {
                lat: parseFloat(this.evento.negocio.latitud),
                lng: parseFloat(this.evento.negocio.longitud)
              }
            }


          })
          .catch((error)  => {
            console.error(error);
            this.overlay = false
          });
    },
    dameEntradas(){
      this.overlay = true
      var bodyFormData = new FormData();

      if(this.conectado){
        bodyFormData.append("personaId",this.usuario.id)
        bodyFormData.append("eventoId",this.evento.id)
      axios
      .post('tickets/entrada',bodyFormData)
          .then(response => {
            this.entradas = response.data
            this.overlay = false

          })
          .catch(error => {
            console.error(error);
          });
      }else{
        axios
            .get('eventoentradas/evento/'+this.evento.id)
            .then(response => {
              this.entradaseve = response.data
              this.overlay = false
            })
            .catch(error => {
              console.error(error);
            });
      }
    },
    inscribirse(item){
      store.dispatch("showInscripcion")
      this.entradaevento =  item.eventoentrada
    },
    comparaFechas(item){
      let fecIni = new Date(item.eventoentrada.fechaInicio + " 00:00:00");
      let fecFin = new Date(item.eventoentrada.fechaFin + " 00:00:00");
      let datenow = new Date();
      if( datenow >= fecIni && datenow<= fecFin){
        return true
      }else{
        return false
      }
    }

  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario",
      evento: "evento"
    }),
    compareDates: function() {
      return (item) => this.comparaFechas(item);
    }
  }
}
</script>
